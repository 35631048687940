import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { useAuth, useLoginWithRedirect } from "@frontegg/react";
// import './index.scss';
// import { App } from './App';
// import { withFrontegg } from './withFrontegg';
import { FronteggProvider, AdminPortal } from '@frontegg/react';
//useAuthUserOrNull, useOnRedirectTo, useAuthActions
//const themeOptions =  {adminBox: {fullScreenMode: true}}
//const headerImage = 'https://assets.frontegg.com/public-frontegg-assets/acme-logo.svg';
//const AppWithFrontegg = withFrontegg(App);

const HostedLogin = () => {
  const { user, isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  return (
    <div className="App">

      {isAuthenticated ? (
        <div>
          <div>
            <img src={user?.profilePictureUrl} alt={user?.name} />
          </div>
          <div>
            <span>Logged in as: {user?.name}</span>
          </div>
          <div>
            <button class="button" data-test-id="accessToken" onClick={() => alert(user.accessToken)}>What is my access token?</button>
            <button class="button" data-test-id="open" onClick={() => AdminPortal.show()}>Click to open admin box</ button>
          </div>
        </div>
      ) : (
        <div>
          <center>
            <iframe src="https://giphy.com/embed/xUA7aT1vNqVWHPY1cA" width="480" height="361" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/movie-comedy-wedding-xUA7aT1vNqVWHPY1cA"></a></p>
            <h1> HELLO AND WELCOME TO THE AWESOME HOSTED LOGIN TEST APP </h1>
            <h2> click the button to be redirected to hosted login </h2>
            <button class="button" data-test-id="openHostedLogin" onClick={ () => loginWithRedirect()} >Click me now!</button>
            <br/>
            <h3> Brought to you by the Frontegg QA team </h3>
          </center>

        </div>
      )}
    </div>
  );
}
ReactDOM.render(
  <BrowserRouter >
    <FronteggProvider contextOptions={{ themeOptions: { AdminPortal: {} }, baseUrl: "https://moabet.loudapi.com", clientId: '2a21d520-ee71-4809-9350-7bc2b6bb6d5f' }} HostedLogin={true}>
      <HostedLogin />
    </FronteggProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
